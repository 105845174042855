<template>
    <div class="flex-1">
        <div class="flex items-center justify-between gap-4 mb-4 wide:mb-8 mt-2">
            <div v-if="has_checks" class="text-lg capitalize font-medium w-full px-3 text-base-content-800">checks available in package</div>
            <Button v-if="has_checks" text="add check" class="inline btn-primary" @click="openAddCheckModal" />
        </div>

        <div class="rounded-md relative">
            <modal-content ref="add-check-to-package" :showFooter="false" :max-width="480" name="addcheck" title="Add Checks to Package">
                <template #content>
                    <div class="flex h-96 pb-4 overflow-y-auto justify-center items-center" v-if="fetchingAllChecks">
                        <Loader />
                    </div>
                    <div v-else class="flex flex-col h-96 pb-4 overflow-y-auto">
                        <div class="min-w-full bg-white pb-6 shadow-sm">
                            <Input :changeCase="true" :shadow="false" placeholder="Search" bg="#fff" margin="0" class="h-9" v-model="searchValue" />
                        </div>

                        <div class="overflow-y-auto py-3 scroll-bar flex-1">
                            <div v-if="unaddedChecks.length > 0" class="w-full">
                                <div class="flex flex-col gap-y-1" v-for="check in unaddedChecks" :key="check.id">
                                    <div class="py-1 text-sm px-2 flex justify-between items-center" style="border-bottom: solid 1px #cdcaca">
                                        <div class="truncate" v-tippy :content="check.name" >
                                            {{ check.name }}
                                        </div>
                                        <div>
                                            <button :disabled="assigningPackages" class="bg-primary hover:bg-primary-focus font-bold text-white-text cursor-pointer rounded-md px-4 py-1.5 text-xs" @click="addCheckToPackage(check)">Add</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <Empty text="Create a check & assign to this package" classList="text-lg"   />
                            </div>
                        </div>
                    </div>
                </template>
                <template #footer>
                    <div class="flex w-full"></div>
                </template>
            </modal-content>
            <div v-if="checksFetch.started" class="max-auto flex flex-col gap-5 justify-center items-center w-full h-80 rounded-lg ring-2-gray-500">
                <Loader />
                Loading Checks...
            </div>
            <div v-else>
                <div v-if="!has_checks" class="w-full flex justify-center items-center flex-1">
                    <Empty text="This package does not have any active check assigned" classList="text-lg" :showBtn="true" btnText="Assign check" @btnClick="openAddCheckModal"  />
                </div>

                <div v-else class="grid gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    <div v-for="(check, index) in packageChecks" :key="check.id">
                        <div class="flex items-center h-10 border-2 rounded-xl py-6 border-indigo-100">
                            <div class="px-4 w-full truncate capitalize py-2">
                                <span class="pr-4 font-semibold text-md">{{ index + 1 }} .</span>
                                <span class="truncate text-base-content-600">{{ check.name }}</span>
                            </div>
                            <div class="px-4 flex  justify-center w-14 py-2 items-center">
                                <button class="cursor-pointer bg-gray-100 text-xs text-base-content-300 hover:bg-primary-focus hover:text-white-text w-6 h-6 flex items-center justify-center rounded-full text-center" @click="removeCheckFromPackage(check)">
                                    <font-awesome-icon icon="times" class="fill-current text-current" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- delete popup confirm -->

        <modal-confirm title="Are you sure?" message="Please confirm you're about to remove this check." ref="confirm-popup"> </modal-confirm>
    </div>
</template>
<script>
import Button from "@/components/button";
import modalContent from "@/components/modal-content";
import axios from "@/axios";
import { getChecksList, getPackagesCheck } from "@/modules/checks/services.js";
import Loader from "@/components/loader";
import Input from "@/components/input";
const modalConfirm = () => import("@/components/modal-confirm");
import { getRandomColors, nameToShort } from "@/plugins/functions";
import Empty from "@shared/empty"

export default {
    name: "checks",
    components: {
        Button,
        Loader,
        "modal-content": modalContent,
        Input,
        modalConfirm,
        Empty,
    },
    props: ["componentData"],
    data: () => ({
        checksFetch: {
            started: false,
            no_data: false,
            has_data: true,
            errored: false,
        },
        confirmRemovePackageFromCheck: false,
        checksData: null,
        isLoading: false,
        values: ["hello", "dad"],
        currentPage: 1,
        allChecks: [],
        searchValue: "",
        packageChecks: [],
        getTotalPages: 3,
        assigningPackages: false,
        fetchingAllChecks: false
    }),
    methods: {
        getRandomColors,
        nameToShort,
        async fetchAllPackageChecks() {
            this.checksFetch.started = true;
            const package_id = this.$route.params.packageName;
            const { checks } = await getPackagesCheck(package_id);
            this.packageChecks = checks;
            this.checksFetch.started = false;
        },
        askRemoveConfirm(check) {
            check.confirmRemovePackageFromCheck = true;
            this.$set(check, "confirmRemovePackageFromCheck", true);
        },
        async addCheckToPackage(check) {
            this.assigningPackages = true;
            const package_id = this.$route.params.packageName;

            const url = `/screening-package/${package_id}/check/${check.id}`;
            
            try {
                await axios.post(url);
                this.$toast.success("Check attached to package.");
                this.checks = this.checks.filter((e) => {
                    return e.id != check.id;
                });
                check.check_id = check.id;
                this.packageChecks.push(check);
            } catch (error) {
                this.assigningPackages = false;
                this.$toast.warning("Could not attach check to package.");
            }

            this.assigningPackages = false;
        },

        async removeCheckFromPackage(check) {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This Check  will be deleted permanently. Please confirm to continue deleting this check.",
            });
            if (promise) {
                const package_id = this.$route.params.packageName;
                const url = `/screening-package/${package_id}/check/${check.check_id}`;

                try {
                    await axios.delete(url);
                    this.$toast.success("Check removed from package.");
                } catch (error) {
                    this.$toast.warning("Could not remove check from package.");
                }
            }
            this.$refs["confirm-popup"].close();
            // this.checks.push(check)
            await this.fetchAllPackageChecks();
        },
        async openAddCheckModal() {
            this.$refs["add-check-to-package"].showModal();
            this.fetchingAllChecks = true
            await this.fetchChecksList();
            this.fetchingAllChecks = false
        },
        async fetchPackageChecks() {},
        async fetchChecksList() {
            const tenant = this.$store.getters.getTenantId;
            let checksData = await getChecksList(tenant);
            checksData.map((el) => {
                if (el.parameters) {
                    let data = JSON.parse(el.parameters);
                    return { ...el, parameters: data.parameters };
                } else {
                    return { ...el };
                }
            });
            this.allChecks = checksData;
            // this.columns = columns;
            this.isLoading = false;
        },
    },
    computed: {
        has_checks() {
            return this.packageChecks.length >= 1;
        },
        checks() {
            return this.allChecks.map((e) => {
                return { ...e, confirmRemovePackageFromCheck: false };
            });
        },
        unaddedChecks() {
            // if (this.searchValue) {
            // let filteredChecks = 

            return this.checks.filter(fil =>  fil?.name?.toLowerCase().includes(this.searchValue?.toLowerCase()) && !this.packageChecks.some(el => el.check_id === fil.id))
            
            //  filteredChecks
            //     return this.checks.filter((e) => {
            //         for (let i of this.packageChecks) {
            //             if (i.id === e.id) {
            //                 return false;
            //             }
            //         }
            //         if (e?.name?.toLowerCase().includes(this.searchValue?.toLowerCase())) return true;
            //     });
            // } else {
            //     return this.checks.filter((e) => {
            //         for (let i of this.packageChecks) {
            //             if (i.id === e.id) {
            //                 return false;
            //             }
            //         }
            //         return true;
            //     });
            // }
        },
    },
    async mounted() {
        this.checksFetch.started = true;
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        this.isLoading = true;
        this.checksData = this.componentData;
        this.isLoading = false;
        await this.fetchAllPackageChecks();
    },
};
</script>
<style scoped lang="scss">
::v-deep {
    .multiselect__tags {
        min-height: 0px !important;
    }
    .multiselect__content-wrapper {
        position: relative !important;
    }
    .multiselect__option {
        min-height: 0 !important;
    }
    .modalContent__content {
        display: flex;
        flex-direction: column;
    }
    button:disabled {
        opacity: 50% !important;
    }
}
</style>
